<template>
    <div class="list-card">
        <div class="card-title">
             {{$t(`message.${dataObj.tag}.name`)}}
            <div class="title-right">
                    <el-button
                        size="mini"
                        @click.stop="deit_dialog = true">编辑
                    </el-button>
                    <el-button
                        size="mini" type="danger" plain
                       >删除
                    </el-button>
                  <el-button v-if="flag === 1"
                    size="mini" type="success"
                    @click.stop="match_dialog = true" plain>匹配
                  </el-button>
                   <el-button v-if="flag === 2"
                    size="mini" type="success"
                    @click.stop="matchDetail_dialog = true" plain>匹配对比
                  </el-button>
                  <el-button
                        size="mini" type="primary" plain
                        >原邮件
                  </el-button>
                <div class="collect">
                    <star-box :fontSize="starFontSize"></star-box>
                </div>
            </div>
        </div>
        <div class="card-detail">
            <div class="detail-left">
                <div v-for="(val,key,i) in dataObj.comment" :key="i" class="item">
                    <span>{{$t(`message.${dataObj.tag}.${key}`)}}: </span>{{val}}
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="item">{{$t(`message.${dataObj.tag}.come`)}}:{{dataObj.come}}</div>
            <div class="item">{{$t(`message.${dataObj.tag}.sender_time`)}}:{{dataObj.sender_time}}</div>
        </div>
        <el-dialog
            title="修改信息"
            v-model="deit_dialog"
             :width="dialogWidth"
            :before-close="handleClose">
            <span>您想要修改该条信息吗？</span>
            <template #footer>
                <span class="dialog-footer">
                <el-button @click="deit_dialog = false">取 消</el-button>
                <el-button type="primary" @click="go_edit()">确 定</el-button>
                </span>
            </template>
        </el-dialog>
        <el-dialog
            title="匹配信息"
            v-model="match_dialog"
             :width="dialogWidth"
            :before-close="handleClose">
            <span>您想要匹配该条信息吗？</span>
            <template #footer>
                <span class="dialog-footer">
                <el-button @click="match_dialog = false">取 消</el-button>
                <el-button type="primary" @click="go_match()">确 定</el-button>
                </span>
            </template>
        </el-dialog>
        <el-dialog
            title="匹配对比"
            v-model="matchDetail_dialog"
             :width="dialogWidth"
            :before-close="handleClose">
            <span>您想要进入匹配对比页面吗？</span>
            <template #footer>
                <span class="dialog-footer">
                <el-button @click="matchDetail_dialog = false">取 消</el-button>
                <el-button type="primary" @click="go_matchDetail()">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import StarBox from '../star-box/StarBox'
export default {
    components:{StarBox},
    props:{
        dataObj:Object, //传进来的信息对象
        flag:Number, // “1”为搜索结果按钮为匹配，“2”为匹配结果按钮为匹配对比
        match_id:Number //从精准匹配页面传过来的需要匹配的id
    },
    data() {
        return{
            starFontSize:'22px',
            deit_dialog:false, //控制修改dialog
            match_dialog:false, //控制匹配
            matchDetail_dialog:false,//控制匹配对比
            dialogWidth:'30%',
        }
    },
    computed:{
        ...mapGetters(['isMobile','dialogWidth']),
        
    },
    methods:{
        go_edit(){
            this.$router.push(`/tonnage/addtonnage/${1}/${this.dataObj.id}`)
        },
        go_match(){
            this.$router.push(`/tonnage/tonmatching/${this.dataObj.id}`)
        },
        go_matchDetail(){
            this.$router.push(`/tonnage/matchdetail/${this.match_id}/${this.dataObj.tag}/${this.dataObj.id}`)
        }
    },
    created(){
        //console.log(this.dataObj.tag)
    }
}
</script>

<style lang="scss" scoped>
 .list-card:hover{
    border:3px solid #409eff;
    //background: #ecf5ff;
 }
 .list-card{
    width:100%;
    height:100%;
    //margin-top: 10px;
    //margin-left: 3px;
    border-radius: 5px;
    padding:5px;
    background:#ecf5ff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .card-title{
        height:30%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        //background: pink;
        .title-right{
            width:35%;
            display: flex;
            justify-content: space-around;
            //background: blue;
            .el-button--mini{
                padding:0 5px !important;
                min-height: 18px;
            }
          .collect{
                width:20%;
                height:100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .title-line{
        width:96%;
        height:2px;
        background:#409EeF;
        border-radius: 3px;
        margin-left: 1%;
    }
    .card-detail{
        box-sizing: border-box;
        padding-left: 1%;
        margin-top: 1%;
        width:100%;
        height:35%;
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        color:#606266;
        //background: brown;
        .detail-left{
            display: flex;
            justify-content: space-between;
            width:100%;
            height:100%;
            color:#606266;
            font-size: 13px;
            //background: chartreuse;
            .item{
                //margin-top: 5%;
                min-width:20%;
                //background: pink;
            }
        }
    }
    .card-footer{
        width:100%;
        //height:12%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        //background: cadetblue;
        .item{
            font-size: 13px;
            color:#606266;
        }
    }
}
</style>