<template>
  <div class="hot-search">
      <div class='hot-item'>
        <el-button style="margin-left:10px">船舶动态</el-button>
        <el-button>货盘</el-button>
        <el-button>船舶动态</el-button>
        <el-button>船舶动态</el-button>
        <el-button>船舶动态</el-button>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        hotList:Array
    }
}
</script>

<style lang="scss" scoped>
.hot-search{
    width:100%;
    height:100%;
    .hot-item{
        display: grid;
        font-size: 15px;
        .item-count{
            flex:2.5;
            display: flex;
            justify-content: center;
            color:#62668b;
        }
    }
}
</style>