<template>
    <div class="serach-list">
        <div class="list-top">
            <div class="top-left">
            </div>
            <div class="top-right">
                <div v-if="!isMobile" class="input-box">
            <!-- 下拉框 -->
            <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <!-- 输入框 -->
            <el-input placeholder="请搜索船名、货名、海域、港口、船公司" prefix-icon="el-icon-search" v-model="searchValue">
            </el-input>
            <el-button class="search-btn" type="text" @click="search">搜一下</el-button>
        </div>
        <!-- :isShow只控制搜索框下面的提示词 -->
                <!-- <div v-if="!isMobile" class="search-area">
                    <meas-search :fontList="measSearchFontList1" :isShow="false"></meas-search>
                </div> -->
                <!-- <div v-if="isMobile" class="search-area-mobile">
                    <meas-search :fontList="measSearchFontList2"></meas-search>
                </div> -->
                <el-tabs v-if="!isMobile" class="list-box" v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="卡片" name="card">
                        <div class="list-area">
                            <div v-for="(item) of show_result" :key="item.id" class='list-item'>
                                <list-card :flag="1" :dataObj="item"></list-card>
                            </div>
                        </div>
                    </el-tab-pane>
                    <!-- <el-tab-pane label="列表" name="table">
                        <div class="list-area2">
                            <div v-for="(item) of show_result" :key="item.id" class='list-item'>
                                <list-card-2 :flag="1" :dataObj="item"></list-card-2>
                            </div>
                        </div>
                    </el-tab-pane> -->
                </el-tabs>
                <div v-if="isMobile" class="list-area-mobile">
                    <div v-for="(item) of show_result" :key="item.id" class='list-item'>
                        <list-card :flag="1" :dataObj="item"></list-card>
                    </div>
                </div>
            </div>
        </div>
        <div class="line"></div>
        <!-- <div v-if="!isMobile" class="list-footer">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                v-model:currentPage="currentPage1" hide-on-single-page="true" background :page-size="5"
                layout="slot, prev, pager, next" :total="100">
                <span>共 100 条</span>
            </el-pagination>
        </div>  -->
        <!-- 搜索分页 -->
            <div v-if="!isMobile" class="list-footer">
                <el-pagination
                        small
                        :prev-text="$t('message.email.lastpage')"
                        :next-text="$t('message.email.nextpage')"
                        layout="slot,prev, pager, next"
                        background
                        v-model:currentPage="currentPage1"
                        :total="pagination.total"
                        :page-size="pagination.pagesize"
                        @current-change="currentChange"
                />
            </div>
        <div v-if="isMobile" class="list-footer-mobile">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                v-model:currentPage="currentPage1" hide-on-single-page=true :page-size="5"
                layout="slot, prev, pager, next" :total="100">
                <span>共 100 条</span>
            </el-pagination>
        </div>
        <div v-if="!isMobile" class="hot-box">
            <div class="hot-search">
                <div class='hot-item'>
                    <!-- <el-button class="featuredBtn" style="margin-left:10px" type="text" -->
                    <!-- @click="STonnageShip">船舶动态(船名)</el-button> -->
                    <el-button class="featuredBtn" type="text" @click="STonnageSea">船舶动态({{ this.total1 }})</el-button>
                    <!-- <el-button class="featuredBtn" type="text" @click="STonnagePort">船舶动态(港口)</el-button> -->
                    <el-button class="featuredBtn" type="text" @click="SCargo">货盘({{ this.total2 }})</el-button>
                    <!-- <el-button class="featuredBtn" type="text" @click="STctSea">租船(海域)</el-button> -->
                    <el-button class="featuredBtn" type="text" @click="STctPort">租船({{ this.total3 }})</el-button>
                    <!-- <el-button class="featuredBtn" type="text" @click="SShiptradeSea">船舶贸易(海域)</el-button> -->
                    <el-button class="featuredBtn" type="text" @click="SShiptradePort">船舶贸易({{ this.total4 }})</el-button>
                    <el-button class="featuredBtn" type="text" @click="SshipdateCargo">航次计划({{ this.total5 }})</el-button>
                    <!-- <el-button class="featuredBtn" type="text" @click="SshipdateShip">航次计划(船名)</el-button> -->
                    <!-- <el-button class="featuredBtn" type="text" @click="SshipdatePort">航次计划(港口)</el-button> -->
                    <!-- <el-button class="featuredBtn" type="text" @click="">船舶规范</el-button>
                    <el-button class="featuredBtn" type="text" @click="">港口信息</el-button>
                    <el-button class="featuredBtn" type="text" @click="">公司信息</el-button> -->
                </div>
            </div>
        </div>
        <el-dialog title="修改信息" v-model="deit_dialog" width="30%" :before-close="handleClose">
            <span>您想要修改该条信息吗？</span>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="deit_dialog = false">取 消</el-button>
                    <el-button type="primary" @click="go_edit()">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { toRaw } from'@vue/reactivity';
import { mapGetters } from 'vuex';
import ListCard from '../../components/list-card/ListCard.vue';
import ListCard2 from '../../components/list-card/ListCard2.vue';
import MeasSearch from '../../components/meas-search/MeasSearch.vue'
import HotSerach from '../../components/hot-search/HotSearch.vue'
import StarBox from '../../components/star-box/StarBox'
import { searchTonnage } from "../../api/tonnage";
import { searchCargo } from '../../api/cargo'
import { searchTCT } from "../../api/tct";
import { searchShiptrade } from '../../api/shiptrade'
import { searchshipdate } from '../../api/voyagePlan'
export default {
    components: { MeasSearch, ListCard, StarBox, ListCard2, HotSerach },
    data() {
        return {
            cargolist: [],
            cargolist1: [],
            options: [{
                value: 1,
                label: '船舶动态'
            }, {
                value: 2,
                label: '货盘'
            }, {
                value: 3,
                label: '租船'
            }, {
                value: 4,
                label: '船舶贸易'
            }, {
                value: 5,
                label: '航次计划'
            }],
            total1: 0,
            total2: 0,
            total3: 0,
            total4: 0,
            total5: 0,
            pagination: {
                current: 0,
                pagesize: 6,
                total: 0
            },
            searchShipNameForm: {
                dwtDown: '',
                dwtUp: '',
                openStartDate: '',
                openEndDate: '',
                built: '',
                sendTime: '',
                languageCode: '',
                openPortId: '',
                openPortCname: '',
                openPortEname: '',
                openSeaareaId: '',
                openSeaareaCname: '',
                openSeaareaEname: '',
                shipId: '',
                shipVesselName: '',
                shipVesselCname: '',
                shipVesselEname: '',
            },
            searchCargoform: {
                //cargoname:'',
                languageCode: '',
                quantityUp: '',
                quantityDown: '',
                laycanStart: '',
                laycanEnd: '',
                sendDays: '',
                cargoNameId: '',
                loadingPortId: '',
                dischargePortId: '',
            },
            searchTCTform: {
                account: '',
                accountId: '',
                dwtMin: '',
                dwtMax: '',
                dwt_type: '',
                laycanStart: '',
                laycanEnd: '',
                durationUp: '',
                durationDown: '',
                durationType: '',
                deliverySeaareaId: '',
                deliverySeaareaName: '',
                deliveryPortId: '',
                deliveryPortName: '',
                redeliverySeaareaId: '',
                redeliverySeaareaName: '',
                redeliveryPortId: '',
                redeliveryPortName: '',
                sendDays: '',
                languageCode: ''
            },
            searchTraform: {
                id: '',
                languageCode: '',
                vesselCname: '',
                vesselEname: '',
                vesselName: '',
                vesselId: '',
                releasingTimeStart: '',
                releasingTimeEnd: '',
                releasingAreaId: '',
                releasingAreaName: '',
                releasingAreaCname: '',
                releasingAreaEname: '',
                releasingPortName: '',
                releasingPortCname: '',
                releasingPortEname: '',
                releasingPortId: '',
                dwtDown: '',
                dwtUp: '',
                dwt_type: '',
                typeId: '',
                typeCname: '',
                typeEname: '',
                typeName: '',
                built: '',
                imo: '',
                sendTime: '',
            },
            shipdateForm: {
                id: '',
                shipId: '',
                shipEname: '',
                shipCname: '',
                shipName: '',
                companyId: '',
                companyName: '',
                linerId: '',
                linerCname: '',
                linerEname: '',
                dischargePortId: '',
                dischargePortEname: '',
                dischargePortCname: '',
                loadingPortId: '',
                loadingPortEname: '',
                loadingPortCname: '',
                intentCargoId: '',
                intentCargoCname: '',
                intentCargoEname: '',
                vessel_name: '',
                liner_cname: '',
                voyage: '',
                laycan_start: '',
                laycan_end: '',
                discharge_port_ename: '',
                loading_port_ename: '',
                voyageTime: ''
            },
            measSearchFontList1: ['15px', '17px', '11%', '19px', '15px', '12px'],
            measSearchFontList2: ['13px', '14px', '11%', '15px', '13px', '12px'],
            currentPage1: 1,
            starFontSize: '28px',
            activeName: 'card',
            searchValue: '',
            value:'',
            search_result: [],
            show_result: [], //真正显示的搜索结果
            tags: ['tonnage', 'cargo', 'tct'],//所有的tag
            countObj: {},//统计每个tag数量
            editObj: {}, //点击编辑按钮，传入的编辑对象
            deit_dialog: false,
            defaultNodekey: ['tonnage', 'cargo', 'tct'],//左侧默认选中的tag
            classifyData: [
                {
                    tag: 'all',
                    title: '搜索结果',
                    count: 0,
                    //disabled:true,
                    children: [
                        {
                            tag: 'tonnage',
                            title: '船舶动态',
                            count: 0
                        },
                        {
                            tag: 'cargo',
                            title: '货盘',
                            count: 0
                        },
                        {
                            tag: 'tct',
                            title: '租船',
                            count: 0
                        },
                    ]
                }
            ],
            defaultProps: {
                children: 'children',
                label(a) {
                    return {
                        title: a.title,
                        count: a.count
                    }
                },
            },
            isMounted: false
        }
    },
    computed: {
        ...mapGetters(['isMobile','cargonamegroup','vesselnamegroup']),
        // checkedKeys() {
        //     if(!this.isMounted) return
        //     else return this.$refs.tree.getCheckedKeys()
        // }
    },
    //监听路由参数变化
    watch:{
//     $route:{
//         handler(to,from){
//             this.mountedMethods()
//             console.log('hi')
//         },
//         immediate:true
//     }
    },
    mounted() {
       this.mountedMethods()
    },
    methods: {
       async mountedMethods(){
            this.value = this.$route.query.value
        this.searchValue = this.$route.query.searchValue
        //获取total
        //根据下拉框选型进行搜索
        // alert(this.value)
            if(this.value == 2){
            this.value = 2
        this.STctPorttotal()
        this.SShiptradePorttotal()
        this.SshipdateCargototal()
        this.STonnagetotal()
            await this.SCargo()
        }else if(this.value == 3){
                this.value = 3
                this.SCargototal()
        this.SShiptradePorttotal()
        this.SshipdateCargototal()
        this.STonnagetotal()
                await this.STctPort()
        }else if(this.value == 4){
            this.value = 4
            this.SCargototal()
        this.STctPorttotal()
        this.SshipdateCargototal()
        this.STonnagetotal()
            await this.SShiptradePort()
        }else if(this.value == 5){
            this.value = 5
            this.SCargototal()
        this.STctPorttotal()
        this.SShiptradePorttotal()
        this.STonnagetotal()
            await this.SshipdateCargo()
        }else{
            this.value = 1
            this.SCargototal()
        this.STctPorttotal()
        this.SShiptradePorttotal()
        this.SshipdateCargototal()
            await this.STonnageSea()
        
    }       
        this.$nextTick(function () {
            var but = document.getElementsByClassName('featuredBtn');
            but[0].style.fontSize = '16px';
            but[0].style.color = 'rgb(79, 154, 224)';
            for (var i = 0; i < but.length; i++) {
                but[i].onclick = function () {
                    for (var i = 0; i < but.length; i++) {
                        but[i].style.color = '';
                        but[i].style.fontSize = '14px';
                    }
                    this.style.color = 'rgb(79, 154, 224)';
                    // 4684ff
                    this.style.fontSize = '16px';
                    // but[i].style.starFontSize = '16px';
                }
            }
        })
        },
        search(){
        if(this.value == 2){
            this.STctPorttotal()
            this.SShiptradePorttotal()
            this.SshipdateCargototal()
            this.STonnagetotal()
            this.SCargo()
        }if(this.value == 3){
            this.SCargototal()
            this.SShiptradePorttotal()
            this.SshipdateCargototal()
            this.STonnagetotal()
            this.STctPort()
        }if(this.value == 4){
            this.SCargototal()
            this.STctPorttotal()
            this.SshipdateCargototal()
            this.STonnagetotal()
            this.SShiptradePort()
        }if(this.value == 5){
            this.SCargototal()
            this.STctPorttotal()
            this.SShiptradePorttotal()
            this.STonnagetotal()
            this.SshipdateCargo()
        }if(this.value == 1){
            this.SCargototal()
            this.STctPorttotal()
            this.SShiptradePorttotal()
            this.SshipdateCargototal()
            this.STonnageSea()
        }
        
        },
        //分页:改变当前页
        currentChange(n) {
        this.pagination.current = n
        if(this.value == 2){
            this.SCargo()
        }if(this.value == 3){
            this.STctPort()
        }if(this.value == 4){
            this.SShiptradePort()
        }if(this.value == 5){
            this.SshipdateCargo()
        }if(this.value == 1){
            this.STonnageSea()
        }
      },

        handleSizeChange(val) {
            //console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
        },
        goDetail() {
            this.$router.push(`/tonnage/addtonnage/${2}/${1}`)
        },
        go_edit() {
            this.$router.push(`/tonnage/addtonnage/${1}/${1}`)
        },
        open_Edit(editObj) {
            this.editObj = editObj
            this.deit_dialog = true
        },
        handleCheckChange(data) {
            // console.log(data)
        },
        getCountList() { //统计每个分类有多少条数据
            this.search_result.forEach(item => {
                if (this.tags.indexOf(item.tag) !== -1) {
                    if (this.countObj[item.tag] == null) {
                        this.countObj[item.tag] = 1
                    } else {
                        this.countObj[item.tag] += 1
                    }
                }
            })
            this.classifyData[0].count = this.search_result.length
            this.classifyData[0].children.forEach(item => {
                item.count = this.countObj[item.tag]
            })
            //console.log(this.countObj)
        },
        STonnageShip() { //获取搜索结果，发起后端请求
            if (this.lang == 'en') {
                this.searchShipNameForm.languageCode = 2
            } else {
                this.searchShipNameForm.languageCode = 1
            }
            this.searchShipNameForm.openSeaareaCname = ''
            this.searchShipNameForm.openSeaareaEname = ''
            this.searchShipNameForm.openSeaareaCname = ''
            this.searchShipNameForm.openSeaareaEname = ''
            this.searchShipNameForm.shipVesselCname = this.$route.query.searchValue
            this.searchShipNameForm.shipVesselEname = this.$route.query.searchValue

            const params = {
                query: this.searchShipNameForm,
                pagination: this.pagination
            }
            searchTonnage(params).then(response => {
                if (response.error_code == 200) {
                    
                    this.show_result = []
                    this.show_result = response.datas;
                    this.pagination.total = response.pagination.total
                }
            })
        },
        STonnagePort() { //获取搜索结果，发起后端请求
            if (this.lang == 'en') {
                this.searchShipNameForm.languageCode = 2
            } else {
                this.searchShipNameForm.languageCode = 1
            }
            this.searchShipNameForm.shipVesselCname = ''
            this.searchShipNameForm.shipVesselEname = ''
            this.searchShipNameForm.openSeaareaCname = ''
            this.searchShipNameForm.openSeaareaEname = ''
            this.searchShipNameForm.openPortCname = this.$route.query.searchValue
            this.searchShipNameForm.openPortEname = this.$route.query.searchValue

            const params = {
                query: this.searchShipNameForm,
                pagination: this.pagination
            }
            searchTonnage(params).then(response => {
                if (response.error_code == 200) {
                    
                    this.show_result = []
                    this.show_result = response.datas;
                    this.pagination.total = response.pagination.total
                }
            })
        },
        STonnageSea() { //获取搜索结果，发起后端请求,this.$route.query.searchValue
            
            //this.value = 1
            //this.$route.query.value = 1
            if (this.lang == 'en') {
                this.searchShipNameForm.languageCode = 2
            } else {
                this.searchShipNameForm.languageCode = 1
            }
            //this.searchShipNameForm.shipVesselCname = ''
            //this.searchShipNameForm.shipVesselName = this.$route.query.searchValue
            this.searchShipNameForm.shipVesselName = this.searchValue
            //this.searchShipNameForm.openPortEname = ''
            //this.searchShipNameForm.openPortCname = ''
            //this.searchShipNameForm.openSeaareaCname = ''
            //this.searchShipNameForm.openSeaareaEname = this.$route.query.searchValue
            //console.log(this.searchShipNameForm.shipVesselName)
            const params = {
                query: this.searchShipNameForm,
                pagination: this.pagination
            }
            searchTonnage(params).then(response => {
                if (response.error_code == 200) {
                    
                    this.show_result = []
                    this.show_result = response.datas;
                    this.pagination.total = response.pagination.total
                    this.total1 = this.pagination.total
                }
            })
            
            //this.$route.query.searchValue = ''
        },
        STonnagetotal() { //获取搜索total结果，发起后端请求,this.$route.query.searchValue
            if (this.lang == 'en') {
                this.searchShipNameForm.languageCode = 2
            } else {
                this.searchShipNameForm.languageCode = 1
            }
            this.searchShipNameForm.shipVesselName = this.searchValue
            const params = {
                query: this.searchShipNameForm,
                pagination: this.pagination
            }
            searchTonnage(params).then(response => {
                if (response.error_code == 200) {
                    this.total1 = response.pagination.total
                }
            })
            
            //this.$route.query.searchValue = ''
        },
        async SCargo() { //根据得到的联想数组赋值cargoNameId，并搜索
            const timer = ms => new Promise(resolve => setTimeout(resolve,ms))
            if(this.searchValue.length !=0){
                //console.log(this.searchValue)
                 this.$store.commit('metadata/querySearchCargo',this.searchValue)
                 await timer(1000) //为了多次搜索等待store的返回，更新group
            //vue获取proxy中target的值
             const Data = toRaw(this.cargonamegroup);
             //console.log(Data)
                if(Data.length != 0){
                    //for(let i=0;i<Data.length;i++) {
                    this.searchCargoform.cargoNameId = Data[0].id
                    //}
                }
                else{
                    this.searchCargoform.cargoNameId = 0
                }
            
            }
            else{
                this.searchCargoform.cargoNameId = ''
            }
            //this.value = 2
            if (this.lang == 'en') {
                this.searchCargoform.languageCode = 2
            } else {
                this.searchCargoform.languageCode = 1
            }
            this.searchCargoform.laycanStart = new Date(this.searchCargoform.laycanStart).getTime()
            this.searchCargoform.laycanEnd = new Date(this.searchCargoform.laycanEnd).getTime()
            //this.searchCargoform.cargoNameId = ''
            const params = {
                query: this.searchCargoform,
                pagination: this.pagination
            }
            searchCargo(params).then(response => {
                if (response.error_code == 200) {
                    
                    this.show_result = []
                    this.show_result = response.datas;
                    this.pagination.total = response.pagination.total
                    this.total2 = this.pagination.total
                     
                }
            })
            //this.cargolist1 = this.cargolist1 + this.cargolist
        //}
        //this.show_result = this.cargolist1
         //   } 
            //this.$route.query.searchValue = ''
        },
        async SCargototal() { //获取搜索结果total，发起后端请求
            const timer = ms => new Promise(resolve => setTimeout(resolve,ms))
            if(this.searchValue.length !=0){
                 this.$store.commit('metadata/querySearchCargo',this.searchValue)
                 await timer(1000) //为了多次搜索等待store的返回，更新group
            //vue获取proxy中target的值
             const Data = toRaw(this.cargonamegroup);
                if(Data.length != 0){
                    //for(let i=0;i<Data.length;i++) {
                    this.searchCargoform.cargoNameId = Data[0].id
                    //}
                }
                else{
                    this.searchCargoform.cargoNameId = 0
                }
            }
            else{
                this.searchCargoform.cargoNameId = ''
            }
            if (this.lang == 'en') {
                this.searchCargoform.languageCode = 2
            } else {
                this.searchCargoform.languageCode = 1
            }
            this.searchCargoform.laycanStart = new Date(this.searchCargoform.laycanStart).getTime()
            this.searchCargoform.laycanEnd = new Date(this.searchCargoform.laycanEnd).getTime()
            //this.searchCargoform.cargoNameId = this.searchValue
            const params = {
                query: this.searchCargoform,
                pagination: this.pagination
            }
            searchCargo(params).then(response => {
                if (response.error_code == 200) {
                    this.total2 = response.pagination.total
                }
            })
            
            //this.$route.query.searchValue = ''
        },
        STctSea() { //获取搜索结果，发起后端请求
            if (this.lang == 'en') {
                this.searchTCTform.languageCode = 2
            } else {
                this.searchTCTform.languageCode = 1
            }
            this.searchTCTform.laycanStart = new Date(this.searchTCTform.laycanStart).getTime()
            this.searchTCTform.laycanEnd = new Date(this.searchTCTform.laycanEnd).getTime()
            this.searchTCTform.deliverySeaareaName = this.searchValue
            this.searchTCTform.deliveryPortName = ''

            this.query = Object.assign(this.searchTCTform)
            const params = {
                query: this.searchTCTform,
                pagination: this.pagination
            }
            searchTCT(params).then((response) => {
                if (response.error_code == 200) {
                    this.show_result = []
                    this.show_result = response.datas;
                    this.pagination.total = response.pagination.total;
                }
            });
        },
        STctPort() { //获取搜索结果，发起后端请求
            
            //this.value = 3
            if (this.lang == 'en') {
                this.searchTCTform.languageCode = 2
            } else {
                this.searchTCTform.languageCode = 1
            }
            this.searchTCTform.laycanStart = new Date(this.searchTCTform.laycanStart).getTime()
            this.searchTCTform.laycanEnd = new Date(this.searchTCTform.laycanEnd).getTime()
            this.searchTCTform.account = this.searchValue
            //this.searchTCTform.deliverySeaareaName = ''

            //this.query = Object.assign(this.searchTCTform)
            const params = {
                query: this.searchTCTform,
                pagination: this.pagination
            }
            searchTCT(params).then((response) => {
                if (response.error_code == 200) {
                    this.show_result = []
                    this.show_result = response.datas;
                    this.pagination.total = response.pagination.total;
                    this.total3 = this.pagination.total
                }
            });
            
            //this.$route.query.searchValue = ''
        },
        STctPorttotal() { //获取搜索结果total，发起后端请求
            if (this.lang == 'en') {
                this.searchTCTform.languageCode = 2
            } else {
                this.searchTCTform.languageCode = 1
            }
            this.searchTCTform.laycanStart = new Date(this.searchTCTform.laycanStart).getTime()
            this.searchTCTform.laycanEnd = new Date(this.searchTCTform.laycanEnd).getTime()
            this.searchTCTform.account = this.searchValue
            const params = {
                query: this.searchTCTform,
                pagination: this.pagination
            }
            searchTCT(params).then((response) => {
                if (response.error_code == 200) {
                    this.total3 = response.pagination.total;
                }
            });
            
            //this.$route.query.searchValue = ''
        },
        SShiptradeSea() { //获取搜索结果，发起后端请求
            this.searchTraform.releasingTimeStart = this.searchTraform.releasingTimeStart == "" || this.searchTraform.releasingTimeStart == null ? "" : (this.searchTraform.releasingTimeStart).getTime()
            this.searchTraform.releasingTimeEnd = this.searchTraform.releasingTimeEnd == "" || this.searchTraform.releasingTimeEnd == null ? "" : (this.searchTraform.releasingTimeEnd).getTime()
            // this.searchTraform.releasingTimeEnd=(this.searchTraform.releasingTimeEnd).getTime()
            this.searchTraform.built = this.searchTraform.built == "" || this.searchTraform.built == null ? "" : this.searchTraform.built.getFullYear().toString();
            if (this.lang == 'en') {
                this.searchTraform.languageCode = 2
            } else {
                this.searchTraform.languageCode = 1
            }
            this.searchTraform.releasingAreaCname = this.$route.query.searchValue
            this.searchTraform.releasingAreaEname = this.$route.query.searchValue
            this.searchTraform.releasingPortCname = ''
            this.searchTraform.releasingPortEname = ''
            this.query = Object.assign(this.searchTraform)
            const params = {
                query: this.searchTraform,
                pagination: this.pagination

            }
            searchShiptrade(params).then(response => {
                if (response.error_code == 200) {
                    this.show_result = []
                    this.show_result = response.datas;
                    this.pagination.total = response.pagination.total
                }
            })

        },
        SShiptradePort() { //获取搜索结果，发起后端请求
            
            //this.value = 4
            //this.searchTraform.releasingTimeStart = this.searchTraform.releasingTimeStart == "" || this.searchTraform.releasingTimeStart == null ? "" : (this.searchTraform.releasingTimeStart).getTime()
            //this.searchTraform.releasingTimeEnd = this.searchTraform.releasingTimeEnd == "" || this.searchTraform.releasingTimeEnd == null ? "" : (this.searchTraform.releasingTimeEnd).getTime()
            // this.searchTraform.releasingTimeEnd=(this.searchTraform.releasingTimeEnd).getTime()
           // this.searchTraform.built = this.searchTraform.built == "" || this.searchTraform.built == null ? "" : this.searchTraform.built.getFullYear().toString();
            if (this.lang == 'en') {
                this.searchTraform.languageCode = 2
            } else {
                this.searchTraform.languageCode = 1
            }
            //this.searchTraform.releasingPortCname = this.$route.query.searchValue
            //this.searchTraform.releasingPortEname = this.$route.query.searchValue
            this.searchTraform.vesselName = this.searchValue
            this.searchTraform.releasingAreaEname = ''
            this.searchTraform.releasingAreaCname = ''

            this.query = Object.assign(this.searchTraform)
            const params = {
                query: this.searchTraform,
                pagination: this.pagination

            }
            searchShiptrade(params).then(response => {
                if (response.error_code == 200) {
                    this.show_result = []
                    this.show_result = response.datas;
                    this.pagination.total = response.pagination.total
                    this.total4 = this.pagination.total
                }
            })
            
            //this.$route.query.searchValue = ''

        },
        SShiptradePorttotal() { //获取搜索结果total，发起后端请求
            if (this.lang == 'en') {
                this.searchTraform.languageCode = 2
            } else {
                this.searchTraform.languageCode = 1
            }
            this.searchTraform.vesselName = this.searchValue
            this.query = Object.assign(this.searchTraform)
            const params = {
                query: this.searchTraform,
                pagination: this.pagination
            }
            searchShiptrade(params).then(response => {
                if (response.error_code == 200) {
                    this.total4 = response.pagination.total
                }
            })
        },
        async SshipdateCargo() { //获取搜索结果，发起后端请求
            const timer2 = ms => new Promise(resolve => setTimeout(resolve,ms))
            if(this.searchValue.length !=0){
                this.$store.commit('metadata/querySearchVessel',this.searchValue)
            //vue获取proxy中target的值
            await timer2(1000) //为了多次搜索等待store的返回，更新group
            const Data = toRaw(this.vesselnamegroup);
                if(Data.length != 0){
                    //for(let i=0;i<Data.length;i++) {
                    this.shipdateForm.shipId = Data[0].id
                    //}
                }
                else{
                    this.shipdateForm.shipId = 0
                }
            }
            else{
                this.shipdateForm.shipId = ''
            }

            //this.value = 5
            if (this.lang == 'en') {
                this.shipdateForm.languageCode = 2
            } else {
                this.shipdateForm.languageCode = 1
            }
            this.shipdateForm.laycan_start = new Date(this.shipdateForm.laycan_start).getTime()
            this.shipdateForm.laycan_end = new Date(this.shipdateForm.laycan_end).getTime()
            //this.shipdateForm.intentCargoCname = this.$route.query.searchValue
            //this.shipdateForm.intentCargoEname = this.$route.query.searchValue
            //this.shipdateForm.companyName = this.searchValue
            this.shipdateForm.loadingPortCname = ''
            this.shipdateForm.loadingPortEname = ''
            //this.shipdateForm.shipCname = ''
            //this.shipdateForm.shipEname = ''
            this.query = Object.assign(this.shipdateForm)
            const params = {
                query: this.shipdateForm,
                pagination: this.pagination
            }
            searchshipdate(params).then(response => {
                // console.log(response)
                if (response.error_code == 200) {
                    this.show_result = []
                    this.show_result = response.datas;
                    this.pagination.total = response.pagination.total
                    this.total5 = this.pagination.total
                }
            })
            
            //this.$route.query.searchValue = ''
        },
        async SshipdateCargototal() { //获取搜索结果total，发起后端请求
            const timer2 = ms => new Promise(resolve => setTimeout(resolve,ms))
            if(this.searchValue.length !=0){
                this.$store.commit('metadata/querySearchVessel',this.searchValue)
            //vue获取proxy中target的值
            await timer2(1000) //为了多次搜索等待store的返回，更新group
            const Data = toRaw(this.vesselnamegroup);
                if(Data.length != 0){
                    //for(let i=0;i<Data.length;i++) {
                    this.shipdateForm.shipId = Data[0].id
                    //}
                }
                else{
                    this.shipdateForm.shipId = 0
                }
            }
            else{
                this.shipdateForm.shipId = ''
            }
            if (this.lang == 'en') {
                this.shipdateForm.languageCode = 2
            } else {
                this.shipdateForm.languageCode = 1
            }
            this.shipdateForm.laycan_start = new Date(this.shipdateForm.laycan_start).getTime()
            this.shipdateForm.laycan_end = new Date(this.shipdateForm.laycan_end).getTime()
            //this.shipdateForm.companyName = this.searchValue
            this.query = Object.assign(this.shipdateForm)
            const params = {
                query: this.shipdateForm,
                pagination: this.pagination
            }
            searchshipdate(params).then(response => {
                if (response.error_code == 200) {
                    this.total5 = response.pagination.total
                }
            })
        },
        SshipdateShip() { //获取搜索结果，发起后端请求
            if (this.lang == 'en') {
                this.shipdateForm.languageCode = 2
            } else {
                this.shipdateForm.languageCode = 1
            }
            this.shipdateForm.laycan_start = new Date(this.shipdateForm.laycan_start).getTime()
            this.shipdateForm.laycan_end = new Date(this.shipdateForm.laycan_end).getTime()
            this.shipdateForm.shipEname = this.$route.query.searchValue
            this.shipdateForm.shipCname = this.$route.query.searchValue
            this.shipdateForm.loadingPortCname = ''
            this.shipdateForm.loadingPortEname = ''
            this.shipdateForm.intentCargoCname = ''
            this.shipdateForm.intentCargoEname = ''
            this.query = Object.assign(this.shipdateForm)
            const params = {
                query: this.shipdateForm,
                pagination: this.pagination
            }
            searchshipdate(params).then(response => {
                // console.log(response)
                if (response.error_code == 200) {
                    this.show_result = []
                    this.show_result = response.datas;
                    this.pagination.total = response.pagination.total
                }
            })
        },
        SshipdatePort() { //获取搜索结果，发起后端请求
            if (this.lang == 'en') {
                this.shipdateForm.languageCode = 2
            } else {
                this.shipdateForm.languageCode = 1
            }
            this.shipdateForm.laycan_start = new Date(this.shipdateForm.laycan_start).getTime()
            this.shipdateForm.laycan_end = new Date(this.shipdateForm.laycan_end).getTime()
            this.shipdateForm.shipEname = ''
            this.shipdateForm.shipCname = ''
            this.shipdateForm.intentCargoCname = ''
            this.shipdateForm.intentCargoEname = ''
            this.shipdateForm.loadingPortCname = this.$route.query.searchValue
            this.shipdateForm.loadingPortEname = this.$route.query.searchValue
            this.query = Object.assign(this.shipdateForm)
            const params = {
                query: this.shipdateForm,
                pagination: this.pagination
            }
            searchshipdate(params).then(response => {
                // console.log(response)
                if (response.error_code == 200) {
                    this.show_result = []
                    this.show_result = response.datas;
                    this.pagination.total = response.pagination.total
                }
            })
        },
        // mounted() {
        //     this.isMounted = true;
        // },
        // created() {
        //     this.STonnage()
        //     this.getCountList()
        // }
    }
}
</script>

<style lang="scss">
.serach-list {
    width: 100%;
    min-height: 700px;


    //position: relative;
    //background: pink;
    .hot-box {
        position: absolute;
        top: 30%;
        right: 5%;
        width: 280px;
        height: 300px;

        //background: pink;
        //border:1px solid pink;
        .hot-search {
            width: 100%;
            height: 100%;

            .hot-item {
                display: grid;
                font-size: 15px;

                .item-count {
                    flex: 2.5;
                    display: flex;
                    justify-content: center;
                    color: #62668b;
                }
            }
        }
    }

    .list-top {
        
        position: relative;
        width: 100%;
        min-height: 650px;
        //height:150%;
        height: 100%;
        display: flex;

        .top-left {
            flex: 1;
            position: relative;

            //background: pink;
            .classify-box {
                //width:100%;
                height: 100px;
                //background: pink;
                margin-top: 180px;
                margin-right: 5px;

                .el-tree-node__children {
                    .el-tree-node__content {
                        padding-left: 10px !important;
                    }
                }
            }
        }

        .top-right {
            width: 88%;
            height: 100%;

            .input-box {
                position: center;
                width: 50%;
                height: 45%;
                display: flex;
                align-items: center;
            }
            .search-btn {
                position: center;
                display: flex;
                align-items: center;
                justify-content: center;
                right: 0;
                width: 10%;
                height: 10%;
                background: #409EeF;
                font-size: var(--fontSize3);
                color: #fff;
                //border-radius: 50px;
            }

            //position: relative;
            .search-area {
                box-sizing: border-box;
                padding-top: 2%;
                width: 50%;
                height: 120px;
                display: flex;
                //background: #ddd;
                // .high-btn{
                //     font-size: 20px;
                //     margin-left: 10%;
                //     width:25%;
                //     height:30%;
                //     position: relative;
                //     top:10%;
                //     display: flex;
                //     align-items: center;
                //     justify-content: center;
                //     color:#409EeF;
                //     i{
                //         margin-right: 5%;
                //     }
                //     //border: 2px solid #409EeF;
                //     padding:5px;
                //     //border-radius: 20px;
                //     //background: pink;
                // }
            }

            .search-area-mobile {
                box-sizing: border-box;
                padding-top: 2%;
                width: 90%;
                height: 100px;

                //display: flex;
                //background: #ddd;
                .high-btn {
                    font-size: 15px;
                    //margin-left: 1%;
                    width: 30%;
                    //height:30%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #409EeF;

                    i {
                        font-size: 16px;
                        margin-right: 5%;
                    }

                    //border: 2px solid #409EeF;
                    padding:5px;
                    //border-radius: 20px;
                    background: pink;
                }
            }

            .list-box {
                width: 70%;
                //background: pink;
                min-height: 60%;
                padding-bottom: 20px;
                box-sizing: border-box;

                .el-tabs__nav-wrap::after {
                    background-color: transparent;
                }

                .list-area {
                    width: 100%;
                    //height:90%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    //background: pink;
                    overflow-y: hidden;

                    .list-item:hover {
                        cursor: pointer;
                    }

                    .list-item {
                        width: 45%;
                        height: 180px;
                        margin-bottom: 35px;
                        margin-right: 5%;
                        // background: #409EeF;
                    }
                }

                .list-area2 {
                    width: 100%;
                    //height:90%;
                    display: flex;
                    flex-wrap: wrap;
                    //background: pink;
                    overflow-y: hidden;

                    .list-item:hover {
                        cursor: pointer;
                    }

                    .list-item {
                        width: 90%;
                        margin-bottom: 35px;
                        margin-right: 5%;
                        // background: #409EeF;
                    }
                }
            }

            .list-box-table {
                width: 95%;
                min-height: 650px;

                .el-table {
                    margin-bottom: 30px;

                    // .el-table--enable-row-hover .el-table__body tr:hover>td{
                    //     cursor: pointer;
                    // }
                    .actions {
                        display: flex;
                        justify-content: space-around;

                        .collect:hover {
                            cursor: pointer;
                        }

                        .collect {
                            width: 28px;
                            height: 28px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }

            .list-area-mobile {
                width: 95%;
                //height:90%;
                display: flex;
                flex-wrap: wrap;
                //background: #aaa;
                overflow-y: hidden;

                .list-item:hover {
                    cursor: pointer;
                }

                .list-item {
                    width: 100%;
                    margin-bottom: 15px;
                    margin-right: 5%;
                    // background: #409EeF;
                }
            }
        }
    }

    .line {
        width: 85%;
        height: 5px;
        margin-left: 10%;
        background: #409EeF;
        border-radius: 5px;
    }

    .list-footer {
        width: 100%;
        height: 80px;
        //background: #f5f5f6;
        padding-top: 1%;

        .el-pagination {
            width: 50%;
            display: flex;
            justify-content: space-around;
            margin-left: 10%;
            //background: pink;
            box-sizing: border-box;

            span {
                color: #606266;
            }
        }
    }

    .featuredBtn {
        color: rgb(115, 126, 142);
        font-weight: 400;
    }

    .featuredBtn.active {
        color: rgb(79, 154, 224);
    }

    .list-footer-mobile {
        width: 100%;
        height: 50px;
        padding-top: 10px;
        margin-left: 8%;

        .el-pagination {
            width: 90%;
            //background: pink;
            box-sizing: border-box;
            display: flex;
            justify-content: space-around;

            span {
                color: #606266;
                margin-right: 20px;
            }

            li {
                min-width: 20px;
            }
        }
    }
}
</style>